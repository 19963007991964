import React, {useContext, useEffect, useState} from "react";
import {GridLayout, Meter} from "morse-react";
import {saveAs} from "file-saver";
import useEDemand, {SendingStages} from "./hooks/useEDemand";
import EDemandService from "./services/EDemandService";
import LoginContext from "../core/contexts/LoginContext";
import DemandService from "../payment/services/DemandService";
import ErrorHandler from "../core/ErrorHandler";

const SendEDemands = (props: any) => {
	const {unqueuedEDemands, queuedEDemands, populateQueuedEDemands, currentState, sendEDemands, progressTotal, stopSendingEDemands, totalHeldEDemands, totalDemands, totalAnnualRenewalToPay, populateEDemandStats} = useEDemand();
	const [queueButtonDisabled, setQueueButtonDisabled] = useState<boolean>(false);
	const [errorCode, setErrorCode] = useState<number>();
	const [errorMessage, setErrorMessage] = useState<string>();
	const loginState = useContext(LoginContext);
	const [paymentsDisabled, setPaymentsDisabled] = useState<any>(props.client.paymentsDisabled);
	const [email, setEmail] = useState<string>("");
	const [disabledPreview, setDisabledPreview] = useState<boolean>(true);
	const [previewMessage, setPreviewMessage] = useState("");
	const [uploadComplete, setUploadComplete] = useState<string>("");
	const [displaySpinner, setDisplaySpinner] = useState<boolean>(false);
	const [uploadMessageStyle, setUploadMessageStyle] = useState<string>("u-marg-left u-color-pos");
	const localeStringParams = {
		style: "currency",
		currency: "GBP",
	};

	useEffect(() => {
		!unqueuedEDemands ? setQueueButtonDisabled(true) : setQueueButtonDisabled(false);
	}, [unqueuedEDemands]);

	useEffect(() => {
		let emailValidation = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
		emailValidation.test(email) ? setDisabledPreview(false) : setDisabledPreview(true);
	}, [email]);

	const manageProgressBar = () => {
		if (progressTotal) {
			return ((progressTotal - queuedEDemands) / progressTotal) * 100;
		} else {
			return 0;
		}
	};

	const togglePayments = async (clientID: number) => {
		try {
			let toggleState = await new DemandService().togglePayments(clientID);
			setPaymentsDisabled(toggleState);
		} catch (e) {
			if (!e.statusCode) {
				setErrorCode(500);
				setErrorMessage("A problem has occurred with our server.");
			} else {
				setErrorCode(e.statusCode);
				setErrorMessage(e.message);
			}
		}
	};

	const downloadDemands = async () => {
		try {
			const csvData = await new EDemandService().getDemandPreferences();
			const csvBlob = new Blob([csvData], {type: "text/csv;charset=utf-8;"});
			saveAs(csvBlob, "Omega-Billing-Data-Export.csv");
		} catch (e) {
			if (!e.statusCode) {
				setErrorCode(500);
				setErrorMessage("A problem has occurred with our server.");
			} else {
				setErrorCode(e.statusCode);
				setErrorMessage(e.message);
			}
		}
	};

	const uploadEDemandPrefs = async (csv) => {
		setDisplaySpinner(true);
		const reader = new FileReader();
		if (csv) {
			reader.readAsText(csv);
			reader.onload = async () => {
				let result: string = await new EDemandService().populateEDemands(reader.result);
				setUploadComplete(result[0]);
				result[1] ? setUploadMessageStyle("u-marg-left u-color-neg") : setUploadMessageStyle("u-marg-left u-color-pos");
				await populateEDemandStats();
				setDisplaySpinner(false);
			};
		}
	};

	const downloadUnQueuedEDemands = async () => {
		try {
			const csvData = await new EDemandService().getUnqueuedEDemands();
			const csvBlob = new Blob([csvData], {type: "text/csv;charset=utf-8;"});
			saveAs(csvBlob, "Total-Omega-Billing-Demands.csv");
		} catch (e) {
			if (!e.statusCode) {
				setErrorCode(500);
				setErrorMessage("A problem has occurred with our server.");
			} else {
				setErrorCode(e.statusCode);
				setErrorMessage(e.message);
			}
		}
	};

	const sendEDemandPreview = async () => {
		const previewEmail = await new EDemandService().sendPreviewEmail(email);
		setPreviewMessage(previewEmail);
	};

	return <>
		{errorMessage ? <ErrorHandler statusCode={errorCode} message={errorMessage}/> : <>
			<>
				<h1 className="c-heading +h1">Data Logging</h1>
				<div className="cc-admin__main">
                    {loginState.user?.staffRoles.includes('omega') ?
					<>
					<div>
                        <h2 className="c-heading +h2">Data Export</h2>
                        <p className="u-marg-bottom">Export the existing annual renewal data - This differs from and cannot
                            be used for import</p>
                        <button className="c-button" onClick={downloadDemands}>EXPORT</button>
                    </div>
					<div>
                        <h2 className="c-heading +h2">Import Data</h2>
                        <p className="u-marg-bottom">Upload data to <strong>replace</strong> the annual renewal data in
                            it's entirety</p>
                        {displaySpinner ?
                            <div className="c-loader +thick" style={{display: "inline-block"}}></div>

                            :
                            <div>
                                <input type="file" accept=".csv" onChange={(e) => {
                                    e.preventDefault();
                                    if (e.target.files) {
                                        uploadEDemandPrefs(e.target.files[0]);
                                    }
                                }
                                }/>
                            </div>
                        }
                        <p className={uploadMessageStyle}>{uploadComplete}</p>
					</div>
					<div>
                        <h2 className="c-heading +h2 u-marg-bottom">Preview Annual Renewals</h2>
                        <p className="u-marg-bottom">This will send the first Annual Renewal notification to the nominated email address.</p>
						<div className="c-field">
                            <label htmlFor="nomEmail" className="c-label">Nominated email address</label>
                        	<input id="nomEmail" type="email" className="c-input +text +fill" onChange={ (e) => {setPreviewMessage(''); setEmail(e.target.value)}}/>
                        </div>
                        <div className="u-marg-top">
                            <label className="u=marg-left">
                                <button disabled={disabledPreview} className="c-button u-pad-left"
                                        onClick={ (e) => {
                                            sendEDemandPreview();
                                        }}>PREVIEW
                                </button>
                                {previewMessage}
                            </label>
                        </div>
					</div>
					</>
					:
					<div />
                    }
                    <div className="s-cms-content">
                        <h2 className="c-heading +h2 u-marg-bottom">Queue and Send</h2>
						<div className="cc-admin__grid">
                        <div className="cc-admin__grid-text">
                            There are
                            {unqueuedEDemands ?
                                <a onClick={downloadUnQueuedEDemands}
                                   style={{pointerEvents: 'auto'}}> {unqueuedEDemands} billing demands </a>
                                :
                                <a onClick={downloadUnQueuedEDemands}
                                   style={{pointerEvents: 'none'}}> {unqueuedEDemands} billing demands </a>
                            }
                            available to queue for annual renewal. Note that if you have already started receiving
                            payments - this may include some paper annual renewals that have paid and signed up for future
                            annual renewal notifications.
                        </div>
						<div className="cc-admin__grid-button">
							<button className="c-button +fill" onClick={async () => {
								await populateQueuedEDemands()
							}} disabled={queueButtonDisabled}>POPULATE QUEUE
							</button>
                        </div>
                        <div className="cc-admin__grid-text">
							There are {queuedEDemands} emails in the queue to send. Click send to
                            start sending in batches of 25. You must leave this browser open for sending to
                            complete.
						</div>
							<div className="cc-admin__grid-button">
							{currentState === SendingStages.Sending ?
								<button className="c-button +long +ghost +neg" onClick={async () => {
									await stopSendingEDemands()
								}}>STOP SENDING</button>
								:
								<button className="c-button +long +ghost +pos" onClick={async () => {
									await sendEDemands()
								}}>START SENDING</button>
							}

							<Meter className="c-meter u-pill u-color-pos u-marg-top" min={0} max={100}
								   value={manageProgressBar()} low={25} optimum={50} high={75}>
								<div><span style={{width: manageProgressBar() + "%"}}></span></div>
							</Meter>
							</div>
                        </div>
					</div>
					<div className="s-cms-content">
                        <h2 className="c-heading +h2">Demand Stats</h2>
                        <table className="c-table +lined">
                            <tbody>
                            <tr>
                                <td>Total Annual Renewals:</td>
                                <td className="c-table__cell +numerical">{totalDemands}</td>
                            </tr>
                            <tr>
                                <td>Total Annual Renewal To Pay:</td>
                                <td className="c-table__cell +numerical">{totalAnnualRenewalToPay.toLocaleString('en-GB', localeStringParams)}</td>
                            </tr>
                            <tr>
                                <td>Total 'Held' from annual renewals:</td>
                                <td className="c-table__cell +numerical">{totalHeldEDemands}</td>
                            </tr>
                            </tbody>
                        </table>
					</div>
					<div className="s-cms-content">
                        <h2 className="c-heading +h2 u-marg-bottom">Toggle Payments</h2>
                        <div>
                            {!paymentsDisabled ?
                                <div>
                                    <p className="u-color-pos">Payments are LIVE</p>
                                    < button className="c-button" onClick={async () => {
                                        let toggle = confirm("Are you sure you want to stop all payments incoming to the site?");
                                        if (toggle) {
                                            await togglePayments(props.client!.id!)
                                        }
                                    }}>TURN OFF
                                    </button>
                                </div>
                                :
                                <div>
                                    <p className="u-color-neg">Payments are STOPPED</p>
                                    < button className="c-button" onClick={async () => {
                                        await togglePayments(props.client!.id!)
                                    }}>TURN ON
                                    </button>
                                </div>
                            }
                        </div>
					</div>
				</div>
			</>
		</>
		}
	</>
};

export default SendEDemands;
