import React, {useState, useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import {DemandWithTotalPaid} from "../../../../shared/types/DemandWithTotalPaid";
import DemandContext from "../contexts/DemandContext";
import DemandService from "../services/DemandService";
import ClientContext from "../../core/contexts/ClientContext";
import Demand from "../../../../shared/entities/Demand";

const PayeeContactDetailsStep = (props: any & {demand: DemandWithTotalPaid}) => {
	const {register, handleSubmit, watch, errors} = useForm<Demand>();
	const [formattedAmount, setFormattedAmount] = useState("");

	const onSubmit = async (data) => {
		props.submitData && props.submitData(data);
		props.demand.email = data.email;
		props.demand.phoneNumber = data.phoneNumber;

		new DemandService().updateDemand(props.demand);

		if (props.submittedData && data.paymentMethod === 'Arrange to pay by cheque') {
			props.changeStep && props.changeStep("ContinueToChequeStep");
		} else if (props.submittedData && data.paymentMethod === 'Arrange to pay by bank transfer') {
			props.changeStep && props.changeStep("ContinueToBACsStep");
		} else {
			props.changeStep && props.changeStep("ContinueToPaymentStep");
		}
	};

	const onChangePaymentMethod = (e) => {
		props.submitData({...props.submittedData, paymentMethod: e.target.value});
	};

	useEffect(() => {
		if (props.amountToPay > 0 && formattedAmount == "") {
			setFormattedAmount(props.amountToPay.toLocaleString("en-UK", {minimumFractionDigits: 2, maximumFractionDigits: 2}));
		}
	}, [props.amountToPay]);

	const onAmountToPayInputChanged = (newAmount) => {
		setFormattedAmount(newAmount);
		props.updateAmountToPay(parseFloat(newAmount));
	};

	const total = props.amountToPay;

	// Step count is just a visual indicator
	let stepCount = 1;

	const client = useContext(ClientContext);
	const demand = useContext(DemandContext);

	if (!props.moto && demand.totalPaid) {
		return (
			<div className="c-alert">
				<p>Sorry, this annual renewal has already been paid.</p>
				<p>
					If you have any concerns or questions please contact our payment line on <b>{client?.paymentsPhoneNumber}</b>
				</p>
			</div>
		);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div>
				<div className="c-alert u-fill-warn u-rounded" role="alert">
					Please check the following information is correct and matches your Omega Church renewal email.
				</div>

				<div className="cc-payment__header">
					<article className="cc-payment__stat">
                        <h2>Invoice</h2>
						<dl>
							<dt>Invoice Number</dt>
							<dd>{props.demand ? props.demand.invoiceNumber : props.demand.invoiceNumber}</dd>
                            {props.moto ? (
								<>
                                    <dt>Original Annual Renewal Cost</dt>
                                    <dd>&pound;{demand.annualRenewalToPay.toLocaleString("en-UK", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</dd>
                                    <dt>Total Payments</dt>
                                    <dd>&pound;{demand.totalPaid.toLocaleString("en-UK", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</dd>
                                    <dt>Balance to pay (£)</dt>
                                    <dd>
                                        <input
											className="c-input +text"
                                            type="number"
                                            step="any"
                                            name="annualRenewalToPay"
                                            onChange={(e) => {
                                                onAmountToPayInputChanged(e.target.value);
                                            }}
                                            value={formattedAmount}
                                            ref={register({required: true})}
                                        />
                                    </dd>
								</>
                            ) : (
                                <>
                                    <dt>Balance to pay</dt>
                                    <dd>&pound; {props.amountToPay.toLocaleString("en-UK", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</dd>
                                </>
                            )}
						</dl>
					</article>
                    <article className="cc-payment__stat">
						<h2>Product</h2>
						<dl>
							<dt>Customer Ref</dt>
							<dd>{props.demand && props.demand.customerRef}</dd>
							<dt>Product Name</dt>
							<dd>{props.demand && props.demand.productName}</dd>
						</dl>
					</article>
				</div>

				<div className="cc-payment__main">
					<div className="cc-payment__details">
						<h2 className="c-heading +h2">Contact Details</h2>
                        <dl className="u-font-large@m u-marg-bottom u-marg-bottom-large@m">
                            <dt>Church Name</dt>
                            <dd>{props.demand && props.demand.churchName}</dd>
                        </dl>
                        <div className="c-fields +equal +inline u-font-large@m">
                            <div className="c-field +required">
                                <label htmlFor="email" className="c-label">
                                    Billing Email Address
                                </label>
                                <span className="c-field__help-text">Please ensure this is the email address for the person responsible for billing and payments</span>
								{props.moto && (
                                    <input
                                        id="email"
                                        type="email"
                                        autoFocus
                                        name="email"
                                        className="c-input +text +fill"
                                        defaultValue={props.demand.email}
                                        ref={register({
                                            validate: (value) => { return value.trim() != ""; },
                                        })}
                                    />
                                )}
                                {!props.moto && (
                                    <input
                                        id="email"
                                        type="email"
                                        autoFocus
                                        name="email"
                                        className="c-input +text +fill"
                                        defaultValue={props.demand.email}
                                        ref={register({required: true, pattern: /^\S+@\S+$/i})}
                                    />
                                )}
                            </div>
                            <div className="c-field">
                                <label htmlFor="phone" className="c-label">
                                    Daytime Telephone
                                </label>
                                <input id="phone" type="phone" name="phoneNumber" className="c-input +text +fill" defaultValue={props.demand.phoneNumber} ref={register} />
                            </div>
                        </div>
					</div>
                    <div className="cc-payment__pay">
						<h2 className="c-heading +h2">Payment</h2>
						<div className="cc-payment__choice">
							<div className="c-field +fill +required u-font-large@m">
                                <label htmlFor="phone" className="c-label">
                                    Payment Method
                                </label>
                                <select className="c-select +fill" id="paymentMethod" name="paymentMethod" ref={register({required: true})} onChange={onChangePaymentMethod} defaultValue={props.paymentMethod ? props.paymentMethod : undefined}>
									<option value="">Please Select</option>
                                    {/* <option>Pay now by credit/debit card</option> */}
                                    <option>Arrange to pay by cheque</option>
                                    <option>Arrange to pay by bank transfer</option>
                                </select>
							</div>
						</div>
						<div className="cc-payment__totals">
                            <table className="c-table +valign +middle u-font-large@m">
                                <tbody>
									<tr>
										<td className="c-table__cell u-align-right">Annual Renewal Total:</td>
										<td className="c-table__cell u-align-right +tight">&pound;{props.amountToPay.toLocaleString("en-UK", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
									</tr>
									<tr className="u-font-bold">
										<td className="c-table__cell u-align-right">Payment Total:</td>
										<td className="c-table__cell u-align-right +tight">
                                            &pound;<span id="total">{total.toLocaleString("en-UK", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
										</td>
									</tr>
                                </tbody>
                            </table>
						</div>
					</div>
				</div>
				<div className="u-align-right u-marg-top-large">
					<button type="submit" className="c-button +large +1">
						Continue
					</button>
                </div>
			</div>
		</form>
	);
};

export default PayeeContactDetailsStep;
