import "reflect-metadata";
import {inject} from "tsyringe";
import ApiTransport from "../src/core/bridges/ApiTransport";

export default class WebsiteConfiguration {
  constructor(
    @inject('ApiTransport') public apiTransport: ApiTransport
  ) {
  }

  public static current: WebsiteConfiguration;
}