import React, {useState} from "react";
import {GridLayout, Button} from "morse-react";
import {useForm} from "react-hook-form";
import Demand from "../../../shared/entities/Demand";
import DemandService from "../payment/services/DemandService";

const UpdateDemandInfo = (props: any) => {
	const [demand] = useState(props.demand);
	const {register, handleSubmit} = useForm<Demand>();
	const [showError, setShowError] = useState<boolean>(false);
	const [showSuccess, setShowSuccess] = useState<boolean>(false);
	const [messageText, setMessageText] = useState("Demand updated");

	const onSubmit = async (data) => {
		const confirmation = confirm("Are you sure you want to update this demand & resend the proforma email?");
		if (confirmation) {
			props.demand.email = data.email;
			props.demand.forename = data.forename;
			props.demand.surname = data.surname;
			props.demand.phoneNumber = data.phoneNumber;

			new DemandService().updateAndResendDemand(props.demand)
				.then((res) => {
					setShowError(false);
					props.searchDemands();
					props.confirmUpdate();
					props.proformaResponse(res["wasEmailSent"]);
				})
				.catch(() => {
					setShowError(true);
				});
		}
	};

	const onUpdateRecord = async (data) => {
				const confirmation = confirm("Are you sure you want to update this demand?");
				if (confirmation) {
					props.demand.email = data.email;
					props.demand.forename = data.forename;
					props.demand.surname = data.surname;
					props.demand.phoneNumber = data.phoneNumber;


					new DemandService()
						.updateDemand(props.demand)
						.then(() => {
							setShowError(false);
							setMessageText("Demand record updated.");
							setShowSuccess(true);
						})
						.catch(() => {
							setShowError(true);
						});
				}
			};
	return (
		<div>
			<form onSubmit={onSubmit}>
				<h2 className="c-heading +h2 u-marg-bottom">Update Demand Details</h2>
				<GridLayout columns={2}>
					<GridLayout.Item>
						<div>
							<GridLayout columns={2}>
								<GridLayout.Item>
									<div className="u-marg-top">
										<label className="c-label">Forename:</label>
									</div>
									<div className="u-marg-top">
										<label className="c-label">Surname:</label>
									</div>
									<div className="u-marg-top">
										<label className="c-label">Phone Number:</label>
									</div>
									<div className="u-marg-top">
										<label className="c-label">Email:</label>
									</div>
								</GridLayout.Item>
								<GridLayout.Item>
											<div className="u-marg-top">
												<input type="text" id="forename" name="forename"
													   className="c-input +text +adaptive" style={{width: "250px"}}
													   defaultValue={props.demand.forename} ref={register()}/>
											</div>
										<div className="u-marg-top">
											<input type="text" id="surname" name="surname"
												   className="c-input +text +adaptive" style={{width: "250px"}}
												   defaultValue={props.demand.surname} ref={register()}/>
										</div>
									<div className="u-marg-top">
										<input type="text" id="phoneNumber" name="phoneNumber"
											   className="c-input +text +adaptive" style={{width: "250px"}}
											   defaultValue={props.demand.phoneNumber} ref={register()}/>
									</div>
									{!showSuccess ? (
										<div className="u-marg-top">
										<input type="email" id="email" name="email"
										className="c-input +text +adaptive" style={{width: "250px"}}
										defaultValue={props.demand.email} ref={register()}/>
										</div>
										) : (
										<div className="c-alert u-fill-pos u-bold u-justify-content-center u-marg-top-large" style={{width: "250px", display: "inline-block"}}>
										{messageText}
										</div>
										)}
								</GridLayout.Item>
							</GridLayout>
							<GridLayout columns={2} className="u-marg-top-large">
								<GridLayout.Item>
									<div className="u-pad-top">
										<Button onClick={props.searchDemands}>Return to Search</Button>
									</div>
								</GridLayout.Item>
								<GridLayout.Item>
									<div className="u-flex u-justify-content-between">
									<div className="u-pad-top u-marg-right">
										<button type="submit" className="c-button +2" onClick={handleSubmit(onUpdateRecord)}>
											Update Record
										</button>
									</div>
									<div className="u-pad-top">
										<button type="submit" className="c-button +1" onClick={handleSubmit(onSubmit)}>
											Update Record and Resend Proforma
										</button>
									</div>
									</div>
								</GridLayout.Item>
								<GridLayout.Item>
									<div>
										{showError && (
											<div className="c-alert u-fill-neg u-bold u-justify-content-center" style={{width: "250px"}}>
												Server could not be updated. Please try again in a few mins
											</div>
										)}
									</div>
								</GridLayout.Item>
							</GridLayout>
						</div>
					</GridLayout.Item>
					<GridLayout.Item>
						<div className="l-wrap-box u-width-2">
							<div className="u-font-small u-pad u-pad--heavy@m">
								<h2 className="c-heading +h3">Demand Details:</h2>
								<ul className="u-list-dash">
									<li>
										<h2 className="c-heading +h4">Product Name</h2> {demand.productName}
									</li>
									<li>
										<h2 className="c-heading +h4">Customer Ref:</h2> {demand.customerRef}
									</li>
									<li>
										<h2 className="c-heading +h4">Invoice Number:</h2> {demand.invoiceNumber}
									</li>
								</ul>
							</div>
						</div>
					</GridLayout.Item>
				</GridLayout>
			</form>
		</div>
	);
};

export default UpdateDemandInfo;
