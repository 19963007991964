import React, {useContext, useEffect} from "react";
import {useAsyncAction} from "../core/hooks/useAsyncAction";
import LoginContext from "../core/contexts/LoginContext";
import AuthService from "./services/AuthService";
import {Link} from "react-router-dom";
import {Button} from "morse-react";
import User from "../../../shared/entities/User";
import ClientContext from "../core/contexts/ClientContext";

const Menu = () => {
	const loginState = useContext(LoginContext);
	const confirmingLoginAction = useAsyncAction();

	const client = useContext(ClientContext);

	if (loginState.isAuthenticated()) {
		useEffect(() => {
			confirmingLoginAction.trigger(async () => {
				try {
					// We simply need to call checkLogin. If checking the current login token fails,
					// the underlying Axios transport will "log us out" and loginState will register
					// that we are no longer logged in.
					new AuthService().checkLogin();
				} catch (error) {}
			});
		}, []);
	}
	return (
		<>
			{confirmingLoginAction.state.slow && <p>Checking Login Details</p>}
			{confirmingLoginAction.state.complete && (
				<div className="l-wrap u-width-3 u-pad-top-large cc-menu">
					<ul>
						{client?.enableMoto && (
							<li className="u-marg">
								<Link to="/admin/moto" className="c-button +large +long">
                                    Record an Annual Renewal Payment
								</Link>
							</li>
						)}
						{(loginState.user as User).staffRoles.includes("batching") && (
							<li className="u-marg">
								<Link to="/admin/batches" className="c-button +large +long +quiet">
                                    Batch Management
								</Link>
							</li>
						)}
						{client?.enableEdemands && (loginState.user as User).staffRoles.includes("edemands") && (
							<li className="u-marg">
								<Link to="/admin/edemands" className="c-button +large +long +quiet">
                                    Data Logging
								</Link>
							</li>
						)}
						{client?.enableResend && (
							<li className="u-marg">
								<Link to="/admin/receipts" className="c-button +large +long +quiet">
                                    Update Payment & Resend Receipts
								</Link>
							</li>
						)}
						{(loginState.user as User).staffRoles.includes("search-demands") && (
							<li className="u-marg">
								<Link to="/admin/demands" className="c-button +large +long +quiet">
                                    Update & Resend Annual Renewal Record
								</Link>
							</li>
						)}
					</ul>
				</div>
			)}
		</>
	);
};

export default Menu;
