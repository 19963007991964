import WebsiteConfiguration from "../WebsiteConfiguration";
import AxiosApiTransport from "../../src/core/bridges/AxiosApiTransport";

class BrowserWebsiteConfiguration extends WebsiteConfiguration {
	constructor() {
		if (!process.env.API_URL) {
			throw new Error("Can't instantiate API as no API_URL environment setting exists.");
		}

		let apiUrl = "";

		if (window.location.hostname.match(/localhost/)) {
			apiUrl = "http://localhost:4000/";
		} else {
			// Replace the {customer} placeholder with the Customer Ref from our front end URL.
			const parts = window.location.hostname.split(".");
			apiUrl = process.env.API_URL.replace("{tenant}", parts[0]);
		}

		super(new AxiosApiTransport(apiUrl));
	}
}

export default BrowserWebsiteConfiguration;
