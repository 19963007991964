import React from 'react';
import Pager from 'react-pager-component';
import {useAsyncAction} from "../core/hooks/useAsyncAction";

const PagedList = (props: IListProps) => {

    const setPageAction = useAsyncAction(30);

    const changePage = async (page: number) => {
        setPageAction.trigger(async () => {
            await props.setPage(page);
        })
    };

    return <>
        {props.children}
        <Pager
            length={props.numberOfPages}
            current={props.page}
            expansion={5}
            onChange={changePage}
        />
        {setPageAction.state.slow && <p>Loading...</p>}
    </>
};

interface IListProps {
    children?: JSX.Element[]|string[]|any;
    numberOfPages: number;
    page: number,
    setPage: (page: number) => void;
}

export default PagedList;