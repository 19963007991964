import {useEffect, useState} from "react";
import IBoundedDataSource from "./IBoundedDataSource";

const usePagedList = function<T>(dataSource: IBoundedDataSource<T>, initialPageSize: number = 20) {

    const intialItems: T[] = [];
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [page, setPage] = useState(1);
    const [items, setItems] = useState(intialItems);
    const [numberOfItems, setNumberOfItems] = useState(0);
    let loadPage: any;

    loadPage = async (page: number) => {
        const {items, totalLength} = await dataSource.getItems((page-1) * pageSize, pageSize);
        setItems(items);
        setNumberOfItems(totalLength);
        setPage(page);
    };

    const numberOfPages = Math.ceil(numberOfItems / pageSize);

    return {
        items,
        pageSize,
        page,
        numberOfItems,
        numberOfPages,
        setPage: async (page: number) => {
            await loadPage(page);
        }
    }
};

export default usePagedList;