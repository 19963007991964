import Demand from "../../../../shared/entities/Demand";
import Payment from "../../../../shared/entities/Payment";
import WebsiteConfiguration from "../../../configurations/WebsiteConfiguration";
import {IDemand} from "../../../../shared/entities/IDemand";
import {DemandWithTotalPaid} from "../../../../shared/types/DemandWithTotalPaid";

class DemandService {
	async getDemand(customerRef: string, invoiceNumber: string): Promise<DemandWithTotalPaid> {
		return await WebsiteConfiguration.current.apiTransport.get<any>("demands/" + encodeURIComponent(customerRef) + "/" + encodeURIComponent(invoiceNumber), {});
	}

	async getDemands(searchTerm: string): Promise<IDemand[]> {
		return await WebsiteConfiguration.current.apiTransport.get<any>("demands", {searchTerm});
	}

	async updateDemand(demand: Demand): Promise<void> {
		return await WebsiteConfiguration.current.apiTransport.put<any>("demands/" + encodeURIComponent(demand.customerRef) + "/" + encodeURIComponent(demand.invoiceNumber), demand);
	}

	async updateAndResendDemand(demand: Demand): Promise<void> {
		return await WebsiteConfiguration.current.apiTransport.put<any>("demands/updateAndResend/" + encodeURIComponent(demand.customerRef) + "/" + encodeURIComponent(demand.invoiceNumber), demand);
	}

	async startPayment(demand: Demand, email: string, phoneNumber: string, paymentMethod: string, amount: number): Promise<Payment> {
		return await WebsiteConfiguration.current.apiTransport.get<any>("demands/" + encodeURIComponent(demand.customerRef) + "/" + encodeURIComponent(demand.invoiceNumber) + "/payment", {
			email,
			phoneNumber,
			paymentMethod,
			amount,
		});
	}

	async updatePayment(payment: Payment): Promise<Payment> {
		return await WebsiteConfiguration.current.apiTransport.put<any>("payments/" + payment.id, payment);
	}

	async confirmPayment(payment: Payment, cardToken: string): Promise<Payment> {
		return await WebsiteConfiguration.current.apiTransport.post<any>("payments/" + payment.id + "/confirm", {
			...payment,
			cardToken,
		});
	}

	async togglePayments(clientID: number): Promise<boolean> {
		return await WebsiteConfiguration.current.apiTransport.get<boolean>("payments/toggle", {clientID});
	}
}

export default DemandService;
