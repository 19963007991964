import {Column, Entity, Index, PrimaryColumn, PrimaryGeneratedColumn} from "typeorm";

@Entity()
export default class Demand {
	@PrimaryGeneratedColumn()
	id?: number;

	@Column ({default: ""})
	public forename: string = "";

	@Column ({default: ""})
	public surname: string = "";

	@Column({default: ""})
	public churchName: string = "";

	@Column({default: ""})
	@Index()
	public customerRef: string = "";

	@Column({default: ""})
	public invoiceNumber: string = "";

	@Column({default: ""})
	public phoneNumber?: string = "";

	@Column({default: ""})
	public email?: string = "";

	@Column({default: ""})
	public productName?: string = "";

	@Column({default: ""})
	public paymentType?: string = "";


	@Column({type: "decimal", default: 0, precision: 8, scale: 2})
	public annualRenewalToPay: number = 0;

	@Column({type: "decimal", default: 0, precision: 8, scale: 2})
	public annualRenewalInPeriod: number = 0;

	@Column({type: "decimal", default: 0, precision: 8, scale: 2})
	public annualRenewalBroughtForward: number = 0;

	@Column({type: "integer", default: 0})
	public eDemandQueueItemId?: number = 0;

	@Column({default: "", length: 150})
	public arrearsT1: string = "";

	@Column({default: "", length: 150})
	public arrearsT2: string = "";

	@Column({default: "", length: 150})
	public arrearsT3: string = "";

	@Column({default: "", length: 150})
	public arrearsT4: string = "";

	@Column({default: "", length: 150})
	public arrearsT5: string = "";

	@Column({type: "datetime", default: null, nullable: true})
	public modifiedDate?: Date;

	@Column({default: ""})
	public modifiedUsername?: string = "";

	@Column({type: "boolean", default: false})
	public holdEmail?: boolean = false;
}
