import React, {useEffect, useLayoutEffect, useState, useRef, useContext} from "react";
import {useForm} from "react-hook-form";
import useDemand from "./hooks/useDemand";
import Step from "../../src/core/components/wizard/Step";
import PayeeContactDetailsStep from "./payment-wizard/PayeeContactDetailsStep";
import PaymentStep from "./payment-wizard/PaymentStep";
import ChequeStep from "./payment-wizard/ChequeStep";
import BACsStep from "./payment-wizard/BACsStep";
import BACsChequePaymentCompleteStep from "./payment-wizard/BACsChequePaymentCompleteStep";
import Wizard from "../core/components/wizard/Wizard";
import Demand from "../../../shared/entities/Demand";
import DemandContext from "./contexts/DemandContext";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import PaymentCompleteStep from "./payment-wizard/PaymentCompleteStep";
import Client from "../../../shared/entities/Client";
import ClientContext from "../core/contexts/ClientContext";
import LoginContext from "../core/contexts/LoginContext";

type PayDemandProps = {
	moto?: boolean;
};

const PayDemand = (props: PayDemandProps) => {
	const loginState = useContext(LoginContext);
	const queryString = require("query-string");
	const [amountToPay, setAmountToPay] = useState<number>(0);
	const parsed = queryString.parse(window.location.search);
	const [paymentMethod, setPaymentMethod] = useState(parsed['paymentMethod']);


	const {register, handleSubmit, watch, errors} = useForm<Demand>({
		defaultValues: {
			customerRef: parsed.t,
		},
	});
	const [formState, setFormState] = useState<Demand & {paymentMethod?: string}>();
	const {demand, getDemandDetails} = useDemand();

	const onSubmit = async (data: any) => {
		try {
			const demand = await getDemandDetails(data.customerRef, data.invoiceNumber);
			setFormState({...demand});
			setAmountToPay(Math.max(demand.annualRenewalToPay - demand.totalPaid, 0));
			setPaymentMethod(parsed)
		} catch (e) {
			alert("Please check your Customer Ref and Invoice Number are correct");
		}
	};

	const pushDemandState = (data: any) => {
		setFormState({...formState, ...data});
	};

	const client: Client = useContext(ClientContext) as Client;
	const stripePromise = loadStripe(client.stripePublicKey);

	return (
		<div>
			{demand.customerRef == "" ? (
				<form onSubmit={handleSubmit(onSubmit)}>
                    {props.moto && (
                        <div className="c-alert u-marg-bottom-large">
                            <div className="u-flex-grow-1">
								<p className="u-font-bold u-marg-none!">Do you require a receipt?</p>
								<ul>
									<li>
										If <b>YES</b>, do you have an email address?
										<ul>
											<li>If <b>YES</b>, continue</li>
											<li className="u-font-bold">If NO, please post your annual renewal into us with an SAE</li>
										</ul>
									</li>
								</ul>
                            </div>
                        </div>
                    )}
					<div className="l-grid +cols-1 +cols-2@m">

						<div className="l-grid__item u-marg-bottom-large">
							<div className="u-width-2 u-max-width-100pc">
								<div className="c-field u-font-large@m +required">
									<label htmlFor="customerRef" className="c-label">{client.customerRefTerm}</label>
									<input id="customerRef" type="text" name="customerRef" ref={register} required maxLength={15} className="c-input +text +fill" defaultValue={parsed["customerRef"]} />
									<span id="customerRefValidation" className="c-field__feedback u-color-neg"></span>
								</div>
								<div className="c-field u-font-large@m +required">
									<label htmlFor="invoiceNum" className="c-label">Invoice Number</label>
									<input id="invoiceNum" type="text" name="invoiceNumber" ref={register} required maxLength={15} className="c-input +text +fill" defaultValue={parsed["invoiceNumber"]}/>
									<span id="InvoiceNumberValidation" className="c-field__feedback u-color-neg"></span>
								</div>
								<p className="u-font-small u-font-base@m u-marg-top-bottom">
									Please note you cannot renew online if your renewal reminder was issued more than 12 months ago.
								</p>
								<button type="submit" className="c-button +1 +large pg-button">
									Continue
								</button>
							</div>
                        </div>
                        <div className="l-grid__item" id="customer-number-help">
                            <div className="u-pad u-pad-large@m u-pad-xl@l u-fill-fade s-cms-content u-rounded" dangerouslySetInnerHTML={{__html: client.customerRefDescription}}></div>
                        </div>
					</div>
				</form>
			) : (
				<div>
					<DemandContext.Provider value={demand}>
						<Elements stripe={stripePromise}>
							<Wizard defaultStep={"PayeeContactDetailsStep"}>
								<Step name={"PayeeContactDetailsStep"}>
									<PayeeContactDetailsStep updateAmountToPay={setAmountToPay} amountToPay={amountToPay} submittedData={formState} submitData={pushDemandState} demand={demand} moto={props.moto} paymentMethod={parsed.paymentMethod}/>
								</Step>
								<Step name={"ContinueToPaymentStep"}>
									<PaymentStep amountToPay={amountToPay} demand={demand} moto={props.moto} submittedData={formState} />
								</Step>
								<Step name={"ContinueToChequeStep"}>
									<ChequeStep amountToPay={amountToPay} demand={demand} submittedData={formState} />
								</Step>
								<Step name={"ContinueToBACsStep"}>
									<BACsStep amountToPay={amountToPay} demand={demand} submittedData={formState} />
								</Step>
								<Step name="PaymentCompleteStep">
									<PaymentCompleteStep submittedData={formState} moto={props.moto} clientWebsite={client.websiteURL} />
								</Step>
								<Step name="BACsChequePaymentCompleteStep">
									<BACsChequePaymentCompleteStep amountToPay={amountToPay} demand={demand} submittedData={formState} moto={props.moto} clientWebsite={client.websiteURL} />
								</Step>
							</Wizard>
						</Elements>
					</DemandContext.Provider>
				</div>
			)}
		</div>
	);
};

export default PayDemand;