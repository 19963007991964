import React, {useEffect, useState} from 'react';
import {Button, GridLayout} from "morse-react";
import {IPayment} from "../core/components/receipts/IPayment";
import usePaymentUpdate from "./hooks/usePaymentUpdate";
import {PaymentStatus} from "../../../shared/entities/Payment";

const UpdatePayment = (props:any) => {

    const [paymentRecord] = useState<IPayment>(props.payment);
    const [emailAddress, setEmailAddress] = useState(props.payment.email);
    const [paymentStatus, setPaymentStatus] = useState(props.payment.status);
    const {updatePaymentStatusDetails} = usePaymentUpdate();
    const [resendDisabled, setResendDisabled] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [messageText, setMessageText] = useState("Payment record updated and receipt email Sent");

    const updateEmailAddress = (value: string) => {
        setEmailAddress(value);
    };

    const onSubmit = async () => {
        setResendDisabled(true);
        paymentRecord.email = emailAddress;
        try {
            const result = await updatePaymentStatusDetails(paymentRecord.id, emailAddress, paymentStatus);

            if(result === "Email Sent") {
                setMessageText("Payment record updated and receipt email Sent");
                setShowSuccess(true);
                setShowError(false);
            } else if(result === "Payment status updated"){
                setMessageText("Payment record updated");
                setShowSuccess(true);
                setShowError(false);
            } else {
                setShowError(true);
            }
        } catch (e) {
            setResendDisabled(false);
            setShowError(true);
        }
    };

    useEffect(() => {
        if(paymentStatus === PaymentStatus.Failed || paymentStatus === PaymentStatus.Success) {
            alert("If you save payment status as Failed or Success you will not be able to edit it again. Please make sure" +
                "any payment being marked as 'Success' is for the full amount");
        }
    }, [paymentStatus]);

    return(
        <div>
            <h2 className="c-heading +h2 u-marg-bottom">Update Payment and Send Receipt</h2>
            <GridLayout columns={2} className="+cols-2@m">
                <GridLayout.Item>
                    <div>
                        <h3 className="c-heading +h3 u-marg-bottom">Update email address if required</h3>
                        <div>
                            {!showSuccess ? (
                                <div className="c-field +fill +required u-font-large@m">
                                    <input
                                        type="text"
                                        className="c-input +text +adaptive"
                                        style={{width: "250px"}}
                                        value={emailAddress}
                                        onChange={(e) => {
                                            updateEmailAddress(e.target.value);
                                        }}
                                    />

                                    <label htmlFor="paymentMethod">
                                    Payment Method
                                    </label>
                                    <select id="paymentMethod" name="paymentMethod" onChange={(e) => {setPaymentStatus(e.target.value)}} defaultValue={props.payment.status}>
                                        <option>Pending</option>
                                        <option>Success</option>
                                        <option>Failed</option>
                                    </select>
                                </div>
                            ) : (
                                <div className="c-alert u-fill-pos u-bold u-justify-content-center" style={{width: "250px", display: "inline-block"}}>
                                    {messageText}
                                </div>
                            )}

                            <button className="c-button +1" type="submit" style={{float: "right"}} onClick={onSubmit} disabled={resendDisabled}>
                                {paymentStatus === PaymentStatus.Success ? "Update payment status and Resend Receipt" : "Update Payment"}
                            </button>

                            {showError && (
                                <div>
                                    <div className="c-alert u-fill-neg u-bold u-justify-content-center" style={{width: "250px"}}>
                                        Failed to Send Email
                                    </div>
                                    <p>Please check the email address is correct and try again in a few seconds</p>
                                </div>
                            )}
                        </div>
                        <div className="u-marg-top-large">
                            <Button onClick={props.searchPayments}>Return to Search</Button>
                        </div>
                    </div>
                </GridLayout.Item>
                <GridLayout.Item>
                    <div className="l-wrap u-width-2">
                        <div className="u-font-small u-pad u-pad--heavy@m">
                            <h2 className="c-heading +h3">Payment Details:</h2>
                            <ul className="u-list-dash">
                                <li>
                                    <h2 className="c-heading +h4">Customer Ref:</h2> {paymentRecord.customerRef}
                                </li>
                                <li>
                                    <h2 className="c-heading +h4">Invoice Number:</h2> {paymentRecord.invoiceNumber}
                                </li>
                                <li>
                                    <h2 className="c-heading +h4">Product Name:</h2> {paymentRecord.productName}
                                </li>
                                <li>
                                    <h2 className="c-heading +h4">Type of Payment:</h2> {paymentRecord.paymentMethod}
                                </li>
                            </ul>
                        </div>
                    </div>
                </GridLayout.Item>
            </GridLayout>
        </div>
    )
};

export default UpdatePayment;