import React from "react";
import User from "../../../../shared/entities/User";
import { decodeToken } from "../../../../api/src/core/utils/AuthHelper";
import AxiosApiTransport from "../bridges/AxiosApiTransport";

export class LoginState  {
    constructor(private setUser: (user?: User) => void, public user?: User) {    
        if (!user){
            const tokenString = localStorage.getItem('token');
            
            if (tokenString){
                const decodedUser = this.decodeToken(tokenString)            
                if (decodedUser){
                    this.setUser(decodedUser);
                } else {
                    this.logout();
                }
            }
        }

        AxiosApiTransport.setClearLoginMethod(() => {
            console.log('called');
            this.logout();
        });
    }

    changeToken(token: string){
        const user = this.decodeToken(token);

        if (user){            
            localStorage.setItem('token', token);
            this.setUser(user);            
        }
    }

    decodeToken(token: string): User|null {        
        const decodedUser = decodeToken<User>(token)
        if (decodedUser){
            AxiosApiTransport.setCurrentUserToken(token);
            return decodedUser;
        }

        return null;
    }

    isAuthenticated(): boolean {
        return !!this.user;
    }

    logout() {
        localStorage.removeItem('token');
        this.setUser(undefined);
    }
}

const LoginContext = React.createContext<LoginState>(new LoginState((user?: User) => {}));

export default LoginContext;
