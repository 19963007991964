import { sign, verify, decode } from 'jsonwebtoken';
import User from '../../../../shared/entities/User';

const JWT_KEY = process.env.JWT_KEY ?? 'docker';

export function decodeToken<T extends User>(authToken: string): T | null {
    try {        
        return decode(authToken) as T;
    } catch (e) {
        return null;
    }
}

export function verifyToken<T extends User>(authToken: string): T | null {
    try {
        const decodedAuthToken = verify(authToken, JWT_KEY) as T;
        decodedAuthToken.expiryTime = new Date(decodedAuthToken.expiryTime);
        return decodedAuthToken;
    } catch (e) {
        return null;
    }
}

export function encodeToken(authToken: User): string {
    return sign(
        {
            ...authToken,
            exp: authToken.expiryTime.getTime() / 1000,
        },
        JWT_KEY,
    );
}
