import React, {useContext, useEffect, useState} from "react";
import PayDemand from "../payment/PayDemand";
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import BatchList from "../batching/BatchList";
import AuthenticatedRoute from "../core/components/routes/AuthenticatedRoute";
import LoginContext from "../core/contexts/LoginContext";
import Login from "./Login";
import Menu from "./Menu";
import SendEDemands from "../edemands/SendEDemands";
import Receipts from "../receipts/Receipts";
import Demands from "../edemands/Demands";
import ClientContext from "../core/contexts/ClientContext";

const Admin = () => {
  const loginState = useContext(LoginContext);

  return <>        
        <Switch>
          <AuthenticatedRoute path="/admin/edemands" roles={["edemands"]}>
              <ClientContext.Consumer>
                  {client =>
                      <SendEDemands client = {client}/>
                  }
              </ClientContext.Consumer>
          </AuthenticatedRoute>          
          <AuthenticatedRoute path="/admin/moto">
              <PayDemand moto={true} />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/admin/batches" roles={["batching"]} >
              <BatchList />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/admin/receipts">
              <Receipts />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/admin/demands" roles={["search-demands"]}>
              <Demands />
          </AuthenticatedRoute>
          <Route exact path="/admin">          
          {loginState.isAuthenticated() ? 
            <Menu /> : 
            <Login />
          }
          </Route>
        </Switch>   
  </>;
};

export default Admin;