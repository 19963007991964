import WebsiteConfiguration from "../../../configurations/WebsiteConfiguration";
import {IElectronicDemand} from "../../core/components/edemands/IElectronicDemand";

class EDemandService {

    async getEDemandStats() : Promise<IElectronicDemand> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("edemands/queue", {});
    }

    async populateQueuedEDemands() : Promise<IElectronicDemand> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("edemands/queue", {});
    }

    async sendBatchedEDemands(): Promise<IElectronicDemand> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("edemands/queue/batches", {});
    }

    async getDemandPreferences(): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("edemands/preferences", {});
    }

    async getUnqueuedEDemands(): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("edemands/unqueued", {});
    }

    async populateEDemands(csvString): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("edemands/upload", {csvString});
    }

    async sendPreviewEmail(email): Promise<string> {
        return await WebsiteConfiguration.current.apiTransport.post<string>("edemands/sendpreview", {email})
    }
}

export default EDemandService;