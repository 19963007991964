import ReceiptService from "../services/ReceiptService";
import {PaymentStatus} from "../../../../shared/entities/Payment";

const usePaymentUpdate = () => {
    const paymentService = new ReceiptService();

    const updatePaymentDetails = async (id: number, email: string) => {
        const paymentDetails = await paymentService.updatePaymentEmail(id, email);
        return paymentDetails;
    };

    const updatePaymentStatusDetails = async (id: number, email: string, paymentStatus: PaymentStatus) => {
        const paymentDetails = await paymentService.updatePaymentStatus(id, email, paymentStatus);

        return paymentDetails;
    };

    return {updatePaymentDetails, updatePaymentStatusDetails}
};

export default usePaymentUpdate;