import WebsiteConfiguration from "../../../configurations/WebsiteConfiguration";
import User from "../../../../shared/entities/User";

export default class AuthService {
    public async login(cognitoAuthResponse: any) {
        try {
            const token = await WebsiteConfiguration.current.apiTransport.post<{authToken:string}>('admin/auth', cognitoAuthResponse);            
            return token.authToken;
        } catch (e) {
            throw e;
        }
    }

    public async checkLogin() {
        try {
            const user = await WebsiteConfiguration.current.apiTransport.get<User>('admin/auth/current', {});            
            return user;
        } catch (e) {
            throw e;
        }
    }
}