import React, {useState} from 'react';
import IWizardProps from "./IWizardProps";
import Step from "./Step";

const Wizard = (props:IWizardProps) => {

    const [wizardState, setWizardState] = useState<IWizardProps>({children: props.children, currentStep: props.defaultStep});

    const changeStep = (stepName: string) => {
        setWizardState({...wizardState, currentStep: stepName})
    };

    return (
        <div>
            {props.children.map((child: any) => {
                const {name} = child.props;
                const {currentStep} = wizardState;

                if (name === currentStep) {
                    return React.cloneElement(child, {changeStep: changeStep})
                }
            })}
        </div>
    )
};

Wizard.Step = Step;

export default Wizard;