import React, {useEffect, useState} from "react";
import SearchPayments from "./SearchPayments";
import ResendReceipts from "./ResendReceipts";
import UpdatePayment from "./UpdatePayment";
import {IPayment} from "../core/components/receipts/IPayment";

const Receipts = () => {

    const [paymentRecord, setPaymenRecord] = useState<IPayment>();
    const [searchTerm, setSearchTerm] = useState('');
    const [updateOrResend, setUpdateOrResend] = useState('');

    const payment = (paymentData: IPayment, searchTerm: string, updateOrResend: string) => {
        setSearchTerm(searchTerm);
        setPaymenRecord(paymentData);
        setUpdateOrResend(updateOrResend);
    };

    const switchToSearchView = () => {
        setPaymenRecord(undefined);
    };

    return(
        <div>
            {!paymentRecord && <SearchPayments onReceiptSelected={payment}
            searchTerm={searchTerm}/>}
            {(paymentRecord && updateOrResend === 'Resend') &&
                <ResendReceipts
                    payment={paymentRecord}
                    searchPayments={switchToSearchView}
                />
            }
            {(paymentRecord && updateOrResend === 'Update') &&
                <UpdatePayment
                    payment={paymentRecord}
                    searchPayments={switchToSearchView}
                />
            }
        </div>
    )
};

export default Receipts;