import ReceiptService from "../services/ReceiptService";
import { useAsyncAction } from "../../core/hooks/useAsyncAction";

const usePayment = () => {
    const paymentService = new ReceiptService();

    const loadPayment = useAsyncAction(0);

    const getPaymentDetails = async (searchTerm:string) => {
        const paymentDetails = await paymentService.getPayment(searchTerm);

        return paymentDetails;
    };

    return {getPaymentDetails}
};

export default usePayment;