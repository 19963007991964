import WebsiteConfiguration from '../../../configurations/WebsiteConfiguration';
import {IPayment} from "../../core/components/receipts/IPayment";

class ReceiptService {

    async getPayment(searchTerm: string): Promise<IPayment[]> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("payments", {searchTerm});
    }

    async updatePaymentEmail(id: number, email: string): Promise<void> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("payments/" + id + "/receipts", {email});
    }

    async updatePaymentStatus(id: number, email: string, paymentStatus: string): Promise<string> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("payments/" + id + "/status", {email, paymentStatus});
    }

}

export default ReceiptService;