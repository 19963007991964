import { useState } from "react";
import Client from "../../../../shared/entities/Client";
import ClientService from "../services/ClientService";
import { useAsyncAction } from "../../core/hooks/useAsyncAction";

const useClient = () => {
    const clientService = new ClientService();
    const [errorThrown, setErrorThrown] = useState(false);

    const loadClient = useAsyncAction(200);    

    const getClientDetails = async () => {
        loadClient.trigger(async () => {
            await clientService.getClient().then(function (client) {
                setClient(client);
                setErrorThrown(false);
            }).catch(() => {
                setErrorThrown(true);
            });
        })
    };

    const initialClient :Client = new Client();
    const[client, setClient] = useState(initialClient);

    return {client, getClientDetails, errorThrown, loading: !loadClient.state.complete, slow: loadClient.state.slow}
};

export default useClient;