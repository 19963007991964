import React, {useEffect, useState} from "react";
import {Button, GridLayout} from "morse-react";
import useDebounce from "../receipts/hooks/useDebounce";
import useDemand from "../payment/hooks/useDemand";
import {IDemand} from "../../../shared/entities/IDemand";

const SearchDemands = (props: any) => {
	const [searchTerm, setSearchTerm] = useState(props.searchTerm);
	const [demands, setDemands] = useState<IDemand[]>();
	const {getDemandsBySearchTerm} = useDemand();
	const [showError, setShowError] = useState<boolean>(false);
	const [loadingFlag, setLoadingFlag] = useState<boolean>(false);
	let noMatchMessage = "No Demands Matching '" + searchTerm + "'";
	let loadMessage = "Loading...";
	let searchMessage = "Search results for '" + searchTerm + "'";

	const populatePaymentRecords = async () => {
		try {
			setShowError(false);
			setLoadingFlag(true);
			await setDemands(await getDemandsBySearchTerm(searchTerm));
		} catch (e) {
			setShowError(true);
		}
	};

	useEffect(() => {
		if (searchTerm.length) {
			populatePaymentRecords();
		}
	}, [searchTerm]);

	useEffect(() => {
		if (demands && demands.length > 0) {
			setLoadingFlag(false);
		}
		searchTerm.length === 0 && setLoadingFlag(false);
		if (demands && demands.length === 0 && searchTerm.length > 0) {
			setLoadingFlag(false);
		}
	}, [demands, searchTerm]);

	const updateSearchTerm = useDebounce((value: string) => {
		if (value.length > 0) {
			setSearchTerm(value);
		} else {
			setDemands([]);
			setSearchTerm("");
		}
	}, 500);

	return (
		<div>
			<h1 className="c-heading +h1 u-marg-bottom">Search Demands</h1>
			<div className={""}>
				<p className="u-font-bold u-marg-bottom-large">Search by customer ref, invoice number, forename, surname, church name, email, phone number or product name:</p>
				<GridLayout columns={2}>
					<GridLayout.Item>
						<input
							type="text"
							autoFocus
							className="c-input +text +adaptive +auto-fill"
							placeholder={"Search..."}
							onChange={(e) => {
								updateSearchTerm(e.target.value);
								props.hideUpdateMessage();
							}}
						/>
					</GridLayout.Item>
					<GridLayout.Item>
						{props.showUpdateSuccess && (
							(props.updateMessage === "Demand Updated and Proforma has been resent") ? (
							<div className="c-alert u-fill-pos u-bold u-justify-content-center" style={{width: "250px", display: "inline-block"}}>
								{props.updateMessage}
							</div>) :
							(
							<div className="c-alert u-fill-neg u-bold u-justify-content-center" style={{width: "250px", display: "inline-block"}}>
								{props.updateMessage}
							</div>)
						)}
					</GridLayout.Item>
				</GridLayout>
				<h2 className="c-heading +h2 u-marg-top">{searchTerm?.length > 0 ? searchMessage : ""}</h2>
			</div>

			<div>
				<table className="u-marg-top u-marg-bottom c-table +lined +head-border">
					<thead>
						<tr>
							<th className="c-table__cell +tight">Customer Ref</th>
							<th className="c-table__cell +tight">Invoice Number</th>
							<th>Forename</th>
							<th>Surname</th>
							<th>Email</th>
							<th>Phone Number</th>
							<th>Product Name</th>
							<th>Church Name</th>
						</tr>
					</thead>
					<tbody>
						{demands &&
							demands.map((demand: IDemand) => {
								return (
									<tr key={demand.id}>
										<td className="c-table__cell +tight">{demand.customerRef}</td>
										<td className="c-table__cell +tight">{demand.invoiceNumber}</td>
										<td>{demand.forename}</td>
										<td>{demand.surname}</td>
										<td>{demand.email}</td>
										<td>{demand.phoneNumber}</td>
										<td>{demand.productName}</td>
										<td>{demand.churchName}</td>
										{/* allowOverlappingEvents on morse Button is stopping the button from throwing an update state on unmounted component warning - See SearchPayments.tsx */}
										<td>
											{
												<Button
													color={Button.Color.Primary}
													allowOverlappingEvents={true}
													onClick={(e) => {
														props.onDemandSelected(demand, searchTerm);
													}}
												>
													Edit Demand Record
												</Button>
											}
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
				{showError && (
					<div>
						<div className="c-alert u-fill-neg u-bold u-justify-content-center" style={{width: "250px"}}>
							Server Error
						</div>
						<p>Try again in a few seconds or contact support</p>
					</div>
				)}
				<h2 className="-heading +h2 u-marg-bottom">{loadingFlag && !showError ? loadMessage : ""}</h2>
				<h2 className="-heading +h2 u-marg-bottom">{demands && demands.length === 0 && searchTerm.length > 0 ? noMatchMessage : ""}</h2>
			</div>
		</div>
	);
};

export default SearchDemands;
