import React, {useState, useContext, useEffect} from "react";
import Demand from "../../../../shared/entities/Demand";
import DemandContext from "../contexts/DemandContext";
import graphic from "../../core/images/graphic-finish.png";

type PaymentCompleteProps = {
	moto?: boolean;
	submittedData?: {[index: string]: any};
	clientWebsite?: string;
};

const PaymentCompleteStep = (props: PaymentCompleteProps) => {

	const today = new Date();
	return (
		<DemandContext.Consumer>
			{(demand) => {
				const realDemand = demand as Demand;
				return (
                    <div className="l-wrap u-width-4">
                        <header className="cc-step-wizard__header">
                            <img src={'/' + graphic} className="cc-step-wizard__graphic" alt="" />
							<h1 className="c-heading +h1">Thank you for renewing your Omega Church support subscription</h1>
                        </header>
                        <div className="cc-step-wizard__body">

						{props.submittedData?.email.trim() != "" &&
							<>
								<div className="cc-step-wizard__column u-font-large@m s-cms-content">
								<p>Below is confirmation of your successful
									payment and renewal. An email with these details will be sent to you shortly. Please
									retain this email as a valid receipt for your payment.
								</p>
								</div>
								<div className="cc-step-wizard__columns u-marg-top-bottom">
									<div className="cc-step-wizard__column u-fill-tint! u-font-large@m">
										<ul>
											<li>
												<span className="cc-step-wizard__label">Payment date</span>
												<span className="cc-step-wizard__detail">{today.toLocaleString('en-GB',{day: '2-digit', month:'2-digit', year: 'numeric'})}</span>
											</li>
											<li>
												<span className="cc-step-wizard__label">Payment method</span>
												<span className="cc-step-wizard__detail">Credit/Debit card</span>
											</li>
											<li>
												<span className="cc-step-wizard__label">Customer Name</span>
												<span className="cc-step-wizard__detail">{props.submittedData?.churchName}</span>
											</li>
											<li>
												<span className="cc-step-wizard__label">Customer Reference</span>
												<span className="cc-step-wizard__detail">{props.submittedData?.customerRef.padStart(4, "0")}</span>
											</li>
											<li>
												<span className="cc-step-wizard__label">Pro Forma Invoice No</span>
												<span className="cc-step-wizard__detail">{props.submittedData?.invoiceNumber}</span>
											</li>
										</ul>
									</div>
									<div className="cc-step-wizard__column u-fill-2! u-font-large@m">
                                        <ul>
                                            <li>
                                                <span className="cc-step-wizard__label +stacked">Renewal details</span>
                                                <span className="cc-step-wizard__detail +large">{props.submittedData?.productName}</span>
                                            </li>
                                            <li>
                                                <span className="cc-step-wizard__label +stacked">Amount paid (Inc VAT)</span>
                                                <span className="cc-step-wizard__detail">£{props.submittedData?.annualRenewalToPay}</span>
                                            </li>
                                        </ul>
									</div>
                                </div>
							</>
						}
							<div className="cc-step-wizard__column u-font-large@m s-cms-content">
								<p>
									If you have any queries regarding your payment or renewal, please contact us by email at
									<a href={"mailto: info@omegachurch.co.uk?subject=Renewal Query: Customer Ref=" + realDemand.customerRef + ", Invoice Number=" + realDemand.invoiceNumber}> info@omegachurch.co.uk </a>
									or by phone on <a href="tel:+4428 3434 1205">028 3434 1205</a>
								</p>
							</div>
                        </div>
                        <div className="u-align-right u-marg-top-large">
                            {props.moto && (
                            	<a href="/admin/moto" className="c-button +large">Add Another</a>
                            )}
                            {!props.moto && (
                                <a href={props.clientWebsite} className="c-button +large">Return Home</a>
                            )}
                        </div>
                    </div>
				);
			}}
		</DemandContext.Consumer>
	);
};

export default PaymentCompleteStep;
