import {Column, Entity, PrimaryColumn, PrimaryGeneratedColumn} from "typeorm";

@Entity()
export default class Client {
	@PrimaryGeneratedColumn()
	id?: number;

	@Column({default: ""})
	public name: string = "";

	@Column("text", {nullable: true})
	logo: string = "";

	@Column({default: ""})
	brandingColor: string = "";

	@Column({default: ""})
	websiteURL: string = "";

	@Column({default: ""})
	stripePublicKey: string = "";

	@Column({default: ""})
	stripePrivateKey: string = "";

	@Column({default: ""})
	customerServiceEmail: string = "";

	@Column({default: ""})
	fromEmailAddress: string = "";

	@Column({default: ""})
	siteName: string = "";

	@Column({default: ""})
	paymentReportSplittingPrefix: string = "";

	@Column({default: ""})
	logoName: string = "";

	@Column({default: ""})
	clientAddress: string = "";

	@Column({type: "boolean", default: false})
	public enableEdemands: boolean = false;

	@Column({type: "boolean", default: false})
	public enableMoto: boolean = false;

	@Column({type: "boolean", default: false})
	public enableResend: boolean = false;

	@Column({default: ""})
	paidTo: string = "";

	@Column({default: "Customer Ref"})
	customerRefTerm: string = "Customer Ref";

	@Column({default: "G230BAL5"})
	customerRefExample: string = "G230BAL5";

	@Column({default: "", length: 800})
	customerRefDescription: string = "";

	@Column({type: "boolean", default: false})
	public paymentsDisabled: boolean = false;

	@Column({default: ""})
	paymentsPhoneNumber: string = "";
}
