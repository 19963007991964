import React, { useContext } from 'react';
import { IClient } from '../clients/IClient';
import ClientContext from '../../contexts/ClientContext';
import { Button, AppLayout } from 'morse-react';
import LoginContext from '../../contexts/LoginContext';
import { useHistory } from "react-router-dom";
import User from '../../../../../shared/entities/User';
import app from "../../../../../api/src/core/api";

const Header = (props: IClient) => {    

    const loginState = useContext(LoginContext);
    const history = useHistory();

    const logout = () => {        
        loginState.logout();
        history.push('/');
    }

    return(
        <ClientContext.Consumer>
            {client =>
                <header className="l-wrap l-app +x +gap-0 cc-header">
                    <div className="l-app__item +start">
                        <a href={client?.websiteURL}><img src={client?.logo} /></a>
                    </div>
                    <div className="l-app__item +middle u-flex u-align-items-center u-justify-content-end">
                        {loginState.isAuthenticated() ?
                            <>
                                <span className="u-marg">Logged in as <b>{(loginState.user as User).username}</b></span>
                                <a href="/admin"><Button color={Button.Color.Primary}>Menu</Button></a>&nbsp;
                                <Button onClick={logout}>Logout</Button>
                            </>
                            : ''}
                    </div>
                </header>
            }
        </ClientContext.Consumer>
    )
};

export default Header;