import React from 'react';
import Header from './Header';
import Footer from './Footer';
import {AppLayout} from 'morse-react/dist/layout/AppLayout';
import {WrapLayout} from 'morse-react/dist/layout/WrapLayout';

const StandardLayout = (props: any) => {

    return (        
        <div className="l-app +y u-height-100vh +gap-0">
            <div className="l-app__item +start">
                <Header />
            </div>
            <div className="l-app__item +middle cc-main">
                <div className="l-wrap cc-main__inner">
                    {props.children}
                </div>
            </div>
            <div className="l-app__item +end">
                <Footer />
            </div>
        </div>
    )
};

export default StandardLayout;