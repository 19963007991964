import EDemandService from "../services/EDemandService";
import {useEffect, useRef, useState} from "react";

export enum SendingStages {
    Sending,
    NotSending,
    FinishedSending
}

const useEDemand = () => {

    const eDemandService = new EDemandService();
    const [unqueuedEDemands, setUnqueuedEDemands] = useState<number>(0);
    const [queuedEDemands, setQueuedEDemands] = useState<number>(0);
    const [totalHeldEDemands, setTotalHeldEDemands] = useState<number>(0);
    const [progressTotal, setProgressTotal] = useState<number>(0);
    const [currentState, setCurrentState] = useState<SendingStages>(SendingStages.NotSending);
    const [totalDemands, setTotalDemands] = useState<number>(0);
    const [totalAnnualRenewalToPay, setTotalAnnualRenewalToPay] = useState<number>(0);

    useEffect(() => {
        populateEDemandStats();
    }, []);

    const populateEDemandStats = async () => {
        const unqueuedEDemands = await eDemandService.getEDemandStats();
        setUnqueuedEDemands(unqueuedEDemands.unqueued);
        setQueuedEDemands(unqueuedEDemands.unsent);
        setTotalHeldEDemands(unqueuedEDemands.totalHeldEDemands);
        setTotalDemands(unqueuedEDemands.totalDemands);
        setTotalAnnualRenewalToPay(unqueuedEDemands.totalAnnualRenewalToPay);
        return await unqueuedEDemands;
    };

    const populateQueuedEDemands = async () => {
        let queuedEDemandTotal = await eDemandService.populateQueuedEDemands();
        setProgressTotal(queuedEDemandTotal.unsent);
        setUnqueuedEDemands(queuedEDemandTotal.unqueued);
        setQueuedEDemands(queuedEDemandTotal.unsent);
        return queuedEDemandTotal;
    };

    useEffect(() => {
        if (currentState === SendingStages.Sending){
            if (queuedEDemands) {
                send();
            } else {
                setCurrentState(SendingStages.FinishedSending)
            }
        } else {
            setCurrentState(SendingStages.NotSending)
        }        
    }, [currentState, queuedEDemands]);

    const send = async () => {
        const batchedEDemands = await eDemandService.sendBatchedEDemands();        
        setUnqueuedEDemands(batchedEDemands.unqueued);
        setQueuedEDemands(batchedEDemands.unsent);        
    };

    const sendEDemands = async () => {
        setCurrentState(SendingStages.Sending);
    };

    const stopSendingEDemands = () => {
        setCurrentState(SendingStages.NotSending);
    };

    return {unqueuedEDemands, queuedEDemands, populateQueuedEDemands, currentState, sendEDemands, progressTotal, stopSendingEDemands, totalHeldEDemands, totalDemands, totalAnnualRenewalToPay, populateEDemandStats};
};

export default useEDemand;