import React, {useEffect, useState, useContext} from "react";
import BatchingService from "./services/BatchingService";
import {AppLayout, Button} from "morse-react";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import {saveAs} from "file-saver";
import LoginContext from "../core/contexts/LoginContext";
import User from "../../../shared/entities/User";
import PagedList from "./PagedList";
import usePagedList from "./usePagedList";
import ErrorHandler from "../core/ErrorHandler";
import useClient from "../multi-client/hooks/useClient";

const BatchList = () => {
	let batchRows: string[] | any;

	dayjs.extend(LocalizedFormat);

	const loginState = useContext(LoginContext);
	const [errorCode, setErrorCode] = useState<number>();
	const [errorMessage, setErrorMessage] = useState<string>();
	const {client, getClientDetails, errorThrown} = useClient();

	const {items, numberOfItems, numberOfPages, setPage, page} = usePagedList(
		{
			getItems: async (offset: number, limit: number) => {
				return new BatchingService().getBatchList(offset, limit);
			},
		},
		10
	);

	useEffect(() => {
		// Always go back to page 1 after the search term has changed.
		// This also triggers the search itself.
		setPage(1);
	}, []);

	batchRows = items.map((item) => {
		const downloadBatch = async function (event) {
			try {
				const csvData = await new BatchingService().getBatchCsv(item.batchNumber);
				const csvBlob = new Blob([csvData]);
				saveAs(csvBlob, "Batch " + item.batchNumber + ".csv");
			} catch (e) {
				if (!e.statusCode) {
					setErrorCode(500);
					setErrorMessage("A problem has occurred with our server.");
				} else {
					setErrorCode(e.statusCode);
					setErrorMessage(e.message);
				}
			}
		};

		return (
			<tr key={item.batchNumber}>
				<td>{item?.batchNumber}</td>
				<td>{dayjs(item?.date).format("DD-MMM-YY")}</td>
				<td className="c-table__cell +tight +numerical">{item?.numberOfPayments}</td>
				<td className="c-table__cell +tight +numerical">{item?.batchTotal.toLocaleString("en-UK", {minimumFractionDigits: 2})}</td>
				<td className="c-table__cell +tight">
					{(loginState.user as User).staffRoles.includes("batch-download") && (
						<Button onClick={downloadBatch}>
							Download
						</Button>
					)}
				</td>
			</tr>
		);
	});

	const downloadAll = async (event) => {
		const now = dayjs().format("DD-MM-YYYY HH:mm");
		try {
			const csvData = await new BatchingService().getPaymentsCsv();
			const csvBlob = new Blob([csvData]);
			saveAs(csvBlob, "All batches " + now + ".csv");
		} catch (e) {
			if (!e.statusCode) {
				setErrorCode(500);
				setErrorMessage("A problem has occurred with our server.");
			} else {
				setErrorCode(e.statusCode);
				setErrorMessage(e.message);
			}
		}
	};

	return (
		<>
			{errorMessage ? (
				<ErrorHandler statusCode={errorCode} message={errorMessage} />
			) : (
				<>
					<AppLayout orientation={AppLayout.Orientation.Horizontal}>
						<AppLayout.Item position={AppLayout.Item.Position.Start}>
							<h1 className="c-heading +h1 u-marg-bottom">Batch List</h1>
						</AppLayout.Item>
						<AppLayout.Item position={AppLayout.Item.Position.End}>
							{loginState.user?.staffRoles.includes("omega") ? (
								<Button color={Button.Color.Primary} onClick={downloadAll}>
									Download All
								</Button>
							) : (
								""
							)}
						</AppLayout.Item>
					</AppLayout>

					<table className="c-table +lined +head-border">

						<thead>
							<tr>
								<th>Batch No.</th>
								<th>Date.</th>
								<th className="c-table__cell +tight +numerical"># Entries.</th>
								<th className="c-table__cell +tight +numerical">Amount</th>
								<th className="c-table__cell +tight"></th>
							</tr>
						</thead>
						<tbody>
							<PagedList numberOfPages={numberOfPages} setPage={setPage} page={page}>
								{batchRows}
							</PagedList>
						</tbody>
					</table>
				</>
			)}
		</>
	);
};

export default BatchList;
