import {PrimaryGeneratedColumn, Entity, Column, Index} from "typeorm";

export enum PaymentStatus {
	Pending = "Pending",
	Success = "Success",
	Failed = "Failed",
}

export enum Source {
	Web = "Web",
	Telephone = "Telephone"
}

@Entity()
export default class Payment {
	@PrimaryGeneratedColumn()
	public id?: number;

	@Column({type: "integer", default: 0})
	public demandId?: number = 0;

	@Column({type: "datetime", nullable: true})
	public createdDate?: Date;

	@Column({type: "datetime", nullable: true})
	public chargeDate?: Date;

	@Column({default: "", length: 25})
	public username: string = "";

	@Column({default: "", length: 8})
	public batchNumber?: string;

	@Column({default: ""})
	public paymentIntent: string = "";

	@Column({default: ""})
	public paymentIntentSecret: string = "";

	@Column({default: "", length: 200})
	public paymentError: string = "";

	@Column({default: ""})
	@Index()
	public customerRef: string = "";

	@Column({default: ""})
	public invoiceNumber: string = "";

	@Column({default: ""})
	public email: string = "";

	@Column({default: ""})
	public phoneNumber: string = "";

	@Column({default: ""})
	public productName?: string;

	@Column({type: "decimal", precision: 8, scale: 2})
	public amount: number = 0;

	@Column({type: "decimal", precision: 8, scale: 2})
	public total: number = 0;

	@Column({default: ""})
	public paymentToken?: string;

	@Column({type: "simple-enum", enum: PaymentStatus})
	public status: PaymentStatus = PaymentStatus.Pending;

	@Column({default: ""})
	public paymentMethod?: string;

	@Column({type: "decimal", precision: 8, scale: 2})
	public due: number = 0;

	@Column({type: "decimal", precision: 8, scale: 2})
	public balance: number = 0;

	@Column({default: ""})
	public callerName?: string;

	@Column({type: "decimal", precision: 8, scale: 2})
	public stripeFee: number = 0;

	@Column({type: "decimal", precision: 8, scale: 2})
	public lodgedToBank: number = 0;
}
