/// <reference path='./App.d.ts'/>

import React, {useEffect, useState} from "react";
import StandardLayout from "./components/layout/StandardLayout";
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import WelcomeStep from "../payment/Welcome";
import "./scss/main.scss";
import useClient from '../multi-client/hooks/useClient';
import Admin from "../admin/Admin";
import ClientContext from "./contexts/ClientContext";
import LoginContext, { LoginState } from "./contexts/LoginContext";
import User from "../../../shared/entities/User";
import ClientLoading from "./ClientLoading";
import ErrorHandler from "./ErrorHandler";

const App = (props: any) => {

    const {client, getClientDetails, errorThrown, loading, slow} = useClient();

    useEffect(() => {
        getClientDetails();
    }, []);

    const [user, setUser] = useState<User>();
    const loginState = new LoginState(setUser, user);    

    if(!loading && !errorThrown) {
        return (
            <ClientContext.Provider value={client}>
                <LoginContext.Provider value={loginState}>
                    <Router>
                        <StandardLayout>
                            <Switch>
                                <Route path="/admin" component={Admin}/>
                                <Route path="/" component={WelcomeStep}/>
                            </Switch>
                        </StandardLayout>
                    </Router>
                </LoginContext.Provider>
            </ClientContext.Provider>
        )
    } else if(!loading && errorThrown) {
        return (
            <div>
                <ErrorHandler/>
            </div>
        )
    } else {
        if (slow) {
            return <div>
                <ClientLoading/>
            </div>;        
        }
    }

    return <></>;
};

export default App;