import {useState} from "react";
import {container} from "tsyringe";
import Demand from "../../../../shared/entities/Demand";
import DemandService from "../services/DemandService";
import {useAsyncAction} from "../../core/hooks/useAsyncAction";
import {DemandWithTotalPaid} from "../../../../shared/types/DemandWithTotalPaid";

const useDemand = () => {
	const demandService = new DemandService();

	const loadDemand = useAsyncAction(0);
	const editDemand = useAsyncAction(50);

	const getDemandDetails = async (customerRef: string, invoiceNumber: string) => {
		const demandDetails = await demandService.getDemand(customerRef, invoiceNumber);

		setDemand(demandDetails);

		return demandDetails;
	};

	const getDemandsBySearchTerm = async (searchTerm: string) => {
		const demandDetails = await demandService.getDemands(searchTerm);

		return demandDetails;
	};

	const initialDemand: DemandWithTotalPaid = {
		...new Demand(),
		totalPaid: 0,
	};

	const [demand, setDemand] = useState(initialDemand);

	return {demand, getDemandDetails, getDemandsBySearchTerm};
};

export default useDemand;
