import React from 'react';
import ClientContext from '../../contexts/ClientContext';

const Footer = () =>{

    return (
        <ClientContext.Consumer>
            {client => 
                <div className="g-footer u-none">
                    <div className="u-pull-left u-2/3">
                        <div className="o-flex o-flex--align-middle o-flex--flex-start">

                        </div>
                    </div>
                    <div className="u-font-xs u-translucent u-align-right u-pull-right u-1/3">
                        <p>{client?.clientAddress.replace(/\n/g, ', ')}</p>
                    </div>
                </div>
            }
        </ClientContext.Consumer>
    )
};

export default Footer;