import React, {useEffect, useState} from "react";
import useDebounce from "./hooks/useDebounce";
import usePayment from "./hooks/usePayment";
import {IPayment} from "../core/components/receipts/IPayment";
import {Button} from "morse-react";
import dayjs from "dayjs";

interface ISearchPayments {
	searchTerm: string;
	onReceiptSelected: Function;
}

const SearchPayments = (props: ISearchPayments) => {
	const [loadingFlag, setLoadingFlag] = useState<boolean>(false);
	const [paymentRecords, setPaymentRecords] = useState<IPayment[]>();
	const [showError, setShowError] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>(props.searchTerm);
	const {getPaymentDetails} = usePayment();
	const [stopSearch, setStopSearch] = useState(false);
	let noMatchMessage = "No Payments Matching '" + searchTerm + "'";
	let loadMessage = "Loading...";
	let searchMessage = "Search results for '" + searchTerm + "'";

	const updateSearchTerm = useDebounce((value: string) => {
		if (value.length > 0) {
			setStopSearch(false);
			setSearchTerm(value);
		} else {
			setPaymentRecords([]);
			setSearchTerm("");
		}
	}, 500);

	useEffect(() => {
		if (searchTerm.length) {
			populatePaymentRecords();
		}
	}, [searchTerm]);

	useEffect(() => {
		paymentRecords && paymentRecords.length > 0 && setLoadingFlag(false);
		searchTerm.length === 0 && setLoadingFlag(false);
		paymentRecords && paymentRecords.length === 0 && searchTerm.length > 0 && setLoadingFlag(false);
	}, [paymentRecords, searchTerm]);

	const populatePaymentRecords = async () => {
		try {
			setShowError(false);
			setLoadingFlag(true);
			await setPaymentRecords(await getPaymentDetails(searchTerm));
		} catch (e) {
			setShowError(true);
		}
	};

	return (
		<div>
			<h1 className="c-heading +h1 u-marg-bottom">Search Payments</h1>
			<div className={""}>
				<p className="u-font-bold u-marg-bottom-large">Search by customer ref, invoice number, email, phone number or product name:</p>
				<input
					type="text"
					autoFocus
					className="c-input +text +adaptive +auto-fill"
					placeholder={"Search..."}
					onChange={(e) => {
						updateSearchTerm(e.target.value);
					}}
				/>
				<h2 className="c-heading +h2 u-marg-top">{searchTerm?.length > 0 ? searchMessage : ""}</h2>
			</div>

			<div>
				<table className="u-marg-top u-marg-bottom c-table +lined +head-border">
					<thead>
						<tr>
							<th>Customer Ref</th>
							<th>Invoice Number</th>
							<th>Church Name</th>
							<th>Email</th>
							<th>Phone Number</th>
							<th>Product Name</th>
							<th>Payment Status</th>
							<th>Date</th>
							<th>Amount</th>
							<th>Receipt Sent</th>
						</tr>
					</thead>
					<tbody>
						{paymentRecords &&
							paymentRecords.map((paymentRecord: IPayment) => {
								let date = dayjs(paymentRecord.createdDate);
								return (
									<tr key={paymentRecord.id}>
										<td>{paymentRecord.customerRef}</td>
										<td>{paymentRecord.invoiceNumber}</td>
										<td>{paymentRecord.demand?.churchName}</td>
										<td>{paymentRecord.email}</td>
										<td>{paymentRecord.phoneNumber}</td>
										<td>{paymentRecord.productName}</td>
										<td className={paymentRecord.status !== "Success" ? "u-color-warn" : ""}>{paymentRecord.status}</td>
										<td>{date.format("DD/MM/YYYY")}</td>
										<td>£{paymentRecord.due}</td>
										<td>{paymentRecord.status === "Success" ? 'yes' : 'no'}</td>
										{/* The warning for setting state on an unmounted component is being raised by this morse Button.
                                     The Button is locking itself while it waits on a returned promise. The error arises when the props
                                     callback sets the state in the parent component (Receipts), which in turn kills the Searchpayments component
                                     before the Button unlocks. There is no real memory leak but this is to be fixed soon */}
										<td>
											{paymentRecord.status === "Success" && (
												<Button
													allowOverlappingEvents={true}
													color={Button.Color.Primary}
													onClick={(e) => {
														props.onReceiptSelected(paymentRecord, searchTerm, "Resend");
													}}
												>
													Resend Receipt
												</Button>
											)}
											{paymentRecord.status === "Pending" && (
												<Button
													allowOverlappingEvents={true}
													color={Button.Color.Primary}
													onClick={(e) => {
														props.onReceiptSelected(paymentRecord, searchTerm, "Update");
													}}
												>
													Update Payment & Send Receipt
												</Button>
											)}
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
				{showError && (
					<div>
						<div className="c-alert u-fill-neg u-bold u-justify-content-center" style={{width: "250px"}}>
							Server Error
						</div>
						<p>Try again in a few seconds or contact support</p>
					</div>
				)}
				<h2 className="-heading +h2 u-marg-bottom">{loadingFlag && !showError ? loadMessage : ""}</h2>
				<h2 className="-heading +h2 u-marg-bottom">{paymentRecords && paymentRecords.length === 0 && searchTerm.length > 0 ? noMatchMessage : ""}</h2>
			</div>
		</div>
	);
};

export default SearchPayments;
