import React from "react";
import {Link} from "react-router-dom";
import {Switch, BrowserRouter as Router, Route} from "react-router-dom";
import PayDemand from "./PayDemand";
import ClientContext from "../../src/core/contexts/ClientContext";

const Welcome = (props: any) => {
	return (
		<>
			<ClientContext.Consumer>
				{(client) => (
					<Router>
						<Switch>
							{!client!.paymentsDisabled && <Route path="/start" exact component={PayDemand} />}
							<Route path="">
									{client && client.paymentsDisabled ? (
                                        <div>
                                            <div className="s-cms-content u-marg-bottom-large u-width-3 u-max-width-100pc">
                                                <p>
                                                    We regret that the facility is currently unavailable. Please check back later to pay your Omega Church annual support subscription.
                                                </p>
                                                <p>We look forward to receiving your Omega Church annual support subscription when the system is available.</p>
												<p>
													If you would prefer not to renew your support subscription, please
													let us know by emailing us at <a href="mailto:info@omegachurch.co.uk">info@omegachurch.co.uk</a>.
												</p>
                                            </div>
                                        </div>
									) : (
										<div>
											<div className="s-cms-content u-marg-bottom-large u-width-3 u-max-width-100pc">
												<p className="u-font-large@m u-font-bold">Renew your Omega Church annual support subscription.</p>
												<p>Welcome to the Omega Church Renewal Portal. From here you can renew
													your annual Omega Church support subscription online. You can choose
													to pay for your subscription via this portal using a credit or debit
													card, or if you would prefer, you can choose to arrange to pay by cheque
													or bank transfer - details of which are provided within this portal.
												</p>

												<p>Please note that you will require your customer reference and pro forma
													invoice number in order to access your renewal. You will find these
													details in the annual Omega Church renewal email that has been sent
													to your registered church email address.
												</p>
												<p>
													If you would prefer not to renew your support subscription, please
													let us know by emailing us at <a href="mailto:info@omegachurch.co.uk">info@omegachurch.co.uk</a>.
												</p>
											</div>
											<div>
												<Link to="/start" className="c-button +1 +large">Continue</Link>
											</div>
										</div>
									)}
							</Route>
						</Switch>
					</Router>
				)}
			</ClientContext.Consumer>
		</>
	);
};

export default Welcome;
