import querystring from "querystring";
import axios from "axios";
import ApiTransport from "./ApiTransport";

export default class AxiosApiTransport implements ApiTransport {
  
  constructor(public apiBase: string){
    axios.interceptors.request.use(request => {
      if (AxiosApiTransport.currentUserToken){
        request.headers["Authorization"] = "Bearer " + AxiosApiTransport.currentUserToken;
      }

      return request;
    });

    axios.interceptors.response.use(response => response, error => {
      if (error.response.status === 401){
          if (AxiosApiTransport.clearLoginMethod){
            AxiosApiTransport.clearLoginMethod();
          }
      }
    });
  }

  static currentUserToken: string;

  public static setCurrentUserToken(userToken: string) {
    AxiosApiTransport.currentUserToken = userToken;    
  }

  static clearLoginMethod: Function;

  static setClearLoginMethod(callback: () => void) {
    AxiosApiTransport.clearLoginMethod = callback;
  }

  async list<T>(endpoint: string, params: any = false): Promise<{items:T[], totalLength: number}> {
    const res = await axios.get<T[]>(
        `${this.apiBase}${endpoint}${
            params ? '?' + querystring.stringify(params) : ''
            }`,
    );

    let contentRange = res.headers['content-range'];
    let totalLength = Number(contentRange?.substring(contentRange.indexOf('/')+1));
    return {items: res.data, totalLength};
  }

  async get<T>(endpoint: string, params: any = false): Promise<any> {
    return await axios.get<T>(
      `${this.apiBase}${endpoint}${
        params ? '?' + querystring.stringify(params) : ''
        }`,
    ).then(res => res.data );
  }

  async post<T>(endpoint: string, data: any): Promise<T> {
    return await axios.post<T>(`${this.apiBase}${endpoint}`, data).then(res => res.data );
  }

  async put<T>(endpoint: string, data: any): Promise<any> {
    return await axios.put<T>(`${this.apiBase}${endpoint}`, data).then(res => res.data );
  }
}