import React, {useEffect} from 'react';
import Demand from '../../../../shared/entities/Demand';
import DemandContext from "../contexts/DemandContext";
import DemandService from '../services/DemandService';
import graphic from "../../core/images/graphic-finish.png";

const BACsChequePaymentCompleteStep = (props:any & {demand:Demand}) => {

    useEffect(() => {
        new DemandService().startPayment(
          props.demand,
          props.submittedData.email,
          props.submittedData.phoneNumber,
          props.submittedData.paymentMethod,
          props.amountToPay);
    }, []);

    const paymentReference = () => {
      if(props.submittedData.paymentMethod === "Arrange to pay by bank transfer") {
          return "bank transfer";
      } else if(props.submittedData.paymentMethod === "Arrange to pay by cheque") {
              return "cheque";
      }
    };

    return (
        <DemandContext.Consumer>
			{(demand) => {
                const realDemand = demand as Demand;
                return (
                <div className="l-wrap u-width-3">
                    <header className="cc-step-wizard__header">
                        <img src={'/' + graphic} className="cc-step-wizard__graphic" alt="" />
                        <h1 className="c-heading +h1">Thank you for renewing your Omega Church support subscription</h1>
                        <p>You have arranged to pay your renewal by {paymentReference()}.</p>
                    </header>
                    <div className="cc-step-wizard__body s-cms-content">
                        <div className="cc-step-wizard__column u-font-large@m">
                            <p>
                                An invoice for your renewal will be sent to you within the next few days. If you have
                                already transferred payment, a confirmation email will be sent to you upon successful receipt of payment.
                            </p>
                            <p>
                                If you have any queries regarding your payment or renewal, please contact us by email at
                                <a href={"mailto: info@omegachurch.co.uk?subject=Renewal Query: Customer Ref=" + realDemand.customerRef + ", Invoice Number=" + realDemand.invoiceNumber}> info@omegachurch.co.uk </a>
                                or by phone on <a href="tel:+4428 3434 1205">028 3434 1205</a>
                            </p>
                        </div>
                    </div>
                    <div className="u-align-right u-marg-top-large">
                        <a href={props.moto ? "/admin" : props.clientWebsite} className="c-button +large">Return Home</a>
                    </div>
                </div>
            );
        }}
        </DemandContext.Consumer>
    );
};

export default BACsChequePaymentCompleteStep;


