import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from "react-dom";
import "reflect-metadata";
import App from "../../src/core/App";
import WebsiteConfiguration from "../WebsiteConfiguration";
import BrowserWebsiteConfiguration from "./BrowserWebsiteConfiguration";

WebsiteConfiguration.current = new BrowserWebsiteConfiguration();

ReactDOM.render(
  <App
  />, document.querySelector('body'));