/**
 * useDebounce hook is a wrapper used to insert a delay between the last event that triggered the
 * the function called and the passed-in callback argument.
 *
 * Usage:
 * By default there is a 200ms delay before the callback function will be executed. This timeout
 * is started again from 0 whenever the event triggering fires again.
 *
 * This avoids 'flickering'
 * in spinners where the action is actually very fast. In those cases the 'flicker' often
 * leads the user to think the action failed, or in some cases aren't sure what they saw
 * and assume something has gone wrong.
 *
 * You can change this value by passing a different integer to the hook function. Zero removes
 * debouncing completely.
 *
 * Considerations:
 * This method of debouncing works for simple implementations. Problems will arrive when passing
 * functions that carry arguments with scope. Binding won't exist when using this hook.
 *
 * @param callback The function that will be debounced.
 * @param delay The number of milliseconds to wait before triggering the callback function.
 */
import {useCallback} from "react";

const useDebounce = (callback: Function, delay: number = 200) => {
    let timeout: any;

    let debouncer = useCallback((...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {callback(...args)}, delay);
    },[]);

    return debouncer;
};

export default useDebounce;