import Client from "../../../../shared/entities/Client";
import WebsiteConfiguration from "../../../configurations/WebsiteConfiguration";

class ClientService {

    async getClient(): Promise<Client> {
        const client = await WebsiteConfiguration.current.apiTransport.get<any>("clients/current", {});

        return client;      
    }

}

export default ClientService;