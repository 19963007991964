import React from 'react';
import Demand from '../../../../shared/entities/Demand';
import graphic from "../../core/images/graphic-transfer.png";

const BACsStep = (props:any & {demand:Demand}) => {

    const onSubmit = (data) => {
        props.changeStep && props.changeStep("BACsChequePaymentCompleteStep");
    };

    const backButton = () => {
        props.changeStep("PayeeContactDetailsStep")
    };

    return (

        <form onSubmit={onSubmit}>
                <header className="cc-step-wizard__header">
                    <span className="c-button +text +link +tight" onClick={backButton}>&lsaquo; Back to Choose Payment</span>
                    <img src={'/' + graphic} className="cc-step-wizard__graphic" alt="" />
                    <h1 className="c-heading +h1">Arrange to pay by bank transfer (BACs)</h1>
                </header>
                <div className="cc-step-wizard__body +columns s-cms-content">
                    <div className="cc-step-wizard__column">
                        <h2 className="c-heading +2">What next?</h2>
                        <ol className="u-font-large@m cc-step-wizard__list">
                            <li>Click the <b>‘renew’</b> button below to register your request to renew your subscription. </li>
                            <li>Within a few days of this request, you will receive an invoice for your subscription.</li>
                            <li>You can choose to send payment now using the details across, or can choose to wait until you receive your invoice.</li>
                            <li>A confirmation email will be sent to you upon successful receipt of payment.</li>
                        </ol>
                    </div>
                    <div className="cc-step-wizard__column">
                        <h2 className="c-heading +2">Bank transfer details</h2>
                        <p>Please arrange a bank transfer payment of <b>£{props.amountToPay.toLocaleString("en-UK", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}</b> using the below account details:
                        </p>
                        <ol className="u-font-large@m cc-step-wizard__list">
                            <li>
                                <span className="cc-step-wizard__label">Account holder name</span>
                                <span className="cc-step-wizard__detail">GCD TECHNOLOGIES LTD.</span>
                            </li>
                            <li>
                                <span className="cc-step-wizard__label">Sort code</span>
                                <span className="cc-step-wizard__detail">980960</span>
                            </li>
                            <li>
                                <span className="cc-step-wizard__label">Account no</span>
                                <span className="cc-step-wizard__detail">00680471</span>
                            </li>
                            <li>
                                <span className="cc-step-wizard__label">Customer reference</span>
                                <span className="cc-step-wizard__detail">{props.demand.customerRef.padStart(4,"0")}</span>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="u-align-right u-marg-top-large">
                    <button type="submit" className="c-button +large +1">
                        Renew
                    </button>
                </div>
        </form>
    )
};

export default BACsStep;


