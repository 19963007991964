import WebsiteConfiguration from '../../../configurations/WebsiteConfiguration';
import Batch from '../../../../shared/entities/Batch';

class BatchingService {

    async getBatchList(offset: number, limit: number): Promise<{items:Batch[], totalLength: number}> {
        return await WebsiteConfiguration.current.apiTransport.list<Batch>(
            "batches/?offset=" + offset + "&limit=" + limit, {});
    }

    async getBatchCsv(batchNumber: number): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("batches/" + batchNumber, {});
    }

    async getPaymentsCsv(): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("batched-payments/", {});
    }
}

export default BatchingService;